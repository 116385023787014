import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
  },
  calendarCtr: {
    backgroundColor: "#11cdef",
    height: "10rem",
  },
  calendar: {
    flexWrap: "nowrap",
    justifyContent: "",

    width: "90vw",
    margin: "auto",
    marginTop: "-4rem",
    backgroundColor: "#FFF",
    paddingTop: "4rem",
    borderRadius: ".5rem",
    color: "#8898aa",
  },
  ctrButton: {
    margin: ".2rem",
    color: "#8898aa",
    backgroundColor: "#FFF",
    padding: ".2rem",
    borderRadius: ".5rem",
  },
}));

export const selectStyle = {
  padding: "10px",
  fontSize: "14px",
  border: "none",
  borderRadius: "5px",
  background: "var(--primary-color)",
  color: "white",
  cursor: "pointer",
  width: "200px",
};
