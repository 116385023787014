import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Swal from "sweetalert2";
import UpdatePersonalInfo from "./UpdatePersonalInfo";
import UpdateContactInfo from "./UpdateContactInfo";
import UpdtaeOfficialInfo from "./UpdtaeOfficialInfo";
import UpdateEquipment from "./UpdateEquipment";
import UpdateSalaryInfo from "./UpdateSalaryInfo";

const UpdateEmployeeTabs = ({ formData, setFormData, data }) => {
  const [value, setValue] = useState("1");
  const user = secureLocalStorage.getItem("admin-info");
  const navigate = useNavigate();
  const token = user?.accessToken;
  console.log(token);
  console.log(data);

  const Id = data?.id;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNidImg = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({
          ...formData,
          nid: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleProfileImg = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormData({
          ...formData,

          image: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };
  const handleNextTab = () => {
    setValue((prevValue) =>
      parseInt(prevValue) >= 5 ? "submit" : String(parseInt(prevValue) + 1)
    );
  };
  const handlePreviousTab = () => {
    setValue((prevValue) =>
      parseInt(prevValue) <= 1 ? "5" : String(parseInt(prevValue) - 1)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formDataToSend = new FormData();

      const formKeys = Object.keys(formData);

      if (!formKeys.length) {
        console.log("No Data Found");
        return;
      }
      formKeys.forEach((formKey) => {
        if (formData[formKey]) {
          formDataToSend.append(formKey, formData[formKey]);
        }
      });

      // for (const key in formData) {
      //   if (Object.prototype.hasOwnProperty.call(formData, key)) {
      //     formDataToSend.append(key, formData[key]);
      //   }
      // }

      //   // Append the image files
      //     formDataToSend.append("nidImage", formData.nid);
      //     formDataToSend.append("profileImage", formData.image);

      const response = await fetch(
        `https://flyfarerp-413411.de.r.appspot.com/api/company/employees/${Id}`,
        {
          method: "PATCH",
          body: formDataToSend,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Employee Add Successfully!",
      }).then(() => {
        // Route to another route after success (replace window.location.href with your desired URL)
        navigate("/dashboard/employee"); // Replace '/success-route' with your actual route
      });
    } catch (error) {
      console.error("Error submitting form data:", error.message);
      // Show error message using SweetAlert
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleFormChange = (event) => {
    console.log(event);
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            indicatorColor="none"
            textColor="white"
            aria-label="home-tab"
            sx={{
              display: "flex",
              borderBottom: "none", // Add this line to remove bottom border
            }}
          >
            <Tab
              sx={{
                marginX: 1, // Add horizontal margin between tabs
                opacity: "1",
                backgroundColor: "var( --gray)",
                color: "var(--black)",

                height: "30px",
                width: "fit-content",
                minHeight: "10px",
                paddingX: 1, // Add horizontal padding to each tab
                fontSize: { xs: "8px", sm: "10px", md: "12px" },
                textTransform: "Capitalize",
                "&.Mui-selected": {
                  backgroundColor: "var(--primary-color)",
                  color: "white",

                  opacity: "1",
                },
              }}
              label="Update Personal Information"
              value="1"
            />
            <Tab
              label="Update Personal Contact Information"
              value="2"
              sx={{
                marginX: 1, // Add horizontal margin between tabs
                opacity: "1",
                backgroundColor: "var( --gray)",
                color: "var(--black)",

                height: "30px",
                width: "fit-content",
                minHeight: "10px",
                paddingX: 1, // Add horizontal padding to each tab
                fontSize: { xs: "8px", sm: "10px", md: "12px" },
                textTransform: "Capitalize",
                "&.Mui-selected": {
                  backgroundColor: "var(--primary-color)",
                  color: "white",

                  opacity: "1",
                },
              }}
            />
            <Tab
              label="Update Official Information"
              value="3"
              sx={{
                marginX: 1, // Add horizontal margin between tabs
                opacity: "1",
                backgroundColor: "var( --gray)",
                color: "var(--black)",

                height: "30px",
                width: "fit-content",
                minHeight: "10px",
                paddingX: 1, // Add horizontal padding to each tab
                fontSize: { xs: "8px", sm: "10px", md: "12px" },
                textTransform: "Capitalize",
                "&.Mui-selected": {
                  backgroundColor: "var(--primary-color)",
                  color: "white",

                  opacity: "1",
                },
              }}
            />
            <Tab
              label="Update Salary Information"
              value="4"
              sx={{
                marginX: 1, // Add horizontal margin between tabs
                opacity: "1",
                backgroundColor: "var( --gray)",
                color: "var(--black)",

                height: "30px",
                width: "fit-content",
                minHeight: "10px",
                paddingX: 1, // Add horizontal padding to each tab
                fontSize: { xs: "8px", sm: "10px", md: "12px" },
                textTransform: "Capitalize",
                "&.Mui-selected": {
                  backgroundColor: "var(--primary-color)",
                  color: "white",

                  opacity: "1",
                },
              }}
            />
            <Tab
              label="Update Equipment Information"
              value="5"
              sx={{
                marginX: 1, // Add horizontal margin between tabs
                opacity: "1",
                backgroundColor: "var( --gray)",
                color: "var(--black)",

                height: "30px",
                width: "fit-content",
                minHeight: "10px",
                paddingX: 1, // Add horizontal padding to each tab
                fontSize: { xs: "8px", sm: "10px", md: "12px" },
                textTransform: "Capitalize",
                "&.Mui-selected": {
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                  opacity: "1",
                },
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          {" "}
          <UpdatePersonalInfo
            formData={formData}
            setFormData={setFormData}
            handleFormChange={handleFormChange}
            handleNidImg={handleNidImg}
            handleProfileImg={handleProfileImg}
          />
        </TabPanel>
        <TabPanel value="2">
          <UpdateContactInfo
            formData={formData}
            handleFormChange={handleFormChange}
          />
        </TabPanel>
        <TabPanel value="3">
          <UpdtaeOfficialInfo
            formData={formData}
            handleFormChange={handleFormChange}
          />
        </TabPanel>
        <TabPanel value="4">
          <UpdateSalaryInfo
            formData={formData}
            handleFormChange={handleFormChange}
          />
        </TabPanel>
        <TabPanel value="5">
          <UpdateEquipment
            formData={formData}
            handleFormChange={handleFormChange}
            handleSubmit={handleSubmit}
          />
        </TabPanel>
      </TabContext>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          {value !== "1" && (
            <Button
              sx={{
                color: "#fff",
                background: "var(--primary-color)",
                mt: 2,
                "&:hover": {
                  background: "var(--primary-color)",
                },
              }}
              onClick={handlePreviousTab}
            >
              Back
            </Button>
          )}
          <Button
            sx={{
              color: "#fff",
              background: "var(--primary-color)",
              mt: 2,
              "&:hover": {
                background: "var(--primary-color)", // Set the background color when hovered
              },
            }}
            onClick={value === "5" ? handleSubmit : handleNextTab}
          >
            {value === "5" ? "Submit " : "Next"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateEmployeeTabs;
