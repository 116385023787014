import { Select, MenuItem, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OnTime from "./SingleOfficeDetails/OnTime";
import Late from "./SingleOfficeDetails/Late";
import EarlyOut from "./SingleOfficeDetails/EarlyOut";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";

const attendenceQueries = [
  {
    show: "Pending",
    value: "check-in-pending",
  },
  {
    show: "Check In",
    value: "check-in-on-time",
  },
  {
    show: "Check In Late",
    value: "check-in-late",
  },
  {
    show: "Check Out Pending",
    value: "check-out-pending",
  },
  {
    show: "Check Out",
    value: "check-out-on-time",
  },

  {
    show: "Check Out Early",
    value: "check-out-early",
  },
  {
    show: "Weekend",
    value: "weekend",
  },
  {
    show: "Holiday",
    value: "holiday",
  },

  {
    show: "Absent",
    value: "absent",
  },
  {
    show: "Leave",
    value: "leave",
  },
];

const DashboardTabs = ({ office, currentDate }) => {
  // console.log(office);
  const [value, setValue] = useState("check-in-pending");
  const [attendence, setAttendence] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState(
    office.length > 0 ? office[0].id : ""
  );
  const [search, setSearch] = useState("");

  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectOffice = (event) => {
    setSelectedOffice(event.target.value);
  };

  const defaultOfficeValue = office.length > 0 ? office[0].id : "";

  useEffect(() => {
    const url = search
      ? `https://flyfarerp-413411.de.r.appspot.com/api/company/dashboard/attendances?date=${moment(
          currentDate
        ).format("YYYY-MM-DD")}&branchId=${selectedOffice}&name=${search}`
      : `https://flyfarerp-413411.de.r.appspot.com/api/company/dashboard/attendances?date=${moment(
          currentDate
        ).format(
          "YYYY-MM-DD"
        )}&attendanceStatus=${value}&branchId=${selectedOffice}&name=${search}`;

    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch data");
        }
        return res.json();
      })
      .then((data) => {
        setAttendence(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currentDate, value, selectedOffice, search]);

  useEffect(() => {
    // Set the default value only if selectedOffice is empty
    if (!selectedOffice && office.length > 0) {
      setSelectedOffice(office[0].id);
    }
  }, [office, selectedOffice]);

  // console.log(selectedOffice);

  return (
    <Box>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedOffice}
        onChange={handleSelectOffice}
        sx={{
          color: "black",
          "& .MuiSelect-icon": {
            color: "black",
          },

          "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
            border: "none",
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& .MuiSelect-select:focus": {
            border: "10px",
          },
          bgcolor: "var(--primary-color)",
          color: "white",
        }}
      >
        <MenuItem value="" disabled>
          Select Office
        </MenuItem>
        {office?.map((data) => (
          <MenuItem key={data.id} value={data.id}>
            {data.branchName}
          </MenuItem>
        ))}
      </Select>

      <Box
        sx={{
          width: "100%",
          background: "#ffffff",
          borderRadius: "8px",
          mt: 2,
          height: "90%",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              "& .MuiTabs-indicator": {
                bgcolor: "var(--primary-color)",
              },
              "& .css-15d83qz-MuiButtonBase-root-MuiTab-root.Mui-selected": {
                color: "var(--primary-color)",
              },
            }}
          >
            <TabList
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              {attendenceQueries.map((query, index) => (
                <Tab
                  key={index}
                  sx={{
                    fontSize: "12px",
                    textTransform: "none",
                    color: value === query.value && "var(--primary-color)",
                  }}
                  label={query.show}
                  value={query.value}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel sx={{ height: "100%", padding: "10px" }} value={value}>
            <OnTime data={attendence} setSearch={setSearch} value={value} />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default DashboardTabs;
