import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import UpdateEmployeeTabs from "./UpdateEmployeeTabs";

const UpdateEmployee = () => {
  const location = useLocation();
  const data = location.state.data.data;

  console.log(data);

  const [formData, setFormData] = useState({
    firstname: data?.firstname,
    lastname: data?.lastname,
    dob: data?.dob,
    bloodGroup: data?.bloodGroup,
    image: data?.image,
    nid: data?.nid,
    religion: data?.religion,
    nationality: data?.nationality,
    presentAddress: data?.presentAddress,
    permanentAddress: data?.permanentAddress,
    personalPhone: data?.personalPhone,
    personalEmail: data?.personalEmail,
    email: data?.email,
    officialPhone: data?.officialPhone,
    emergencyContact: data?.emergencyContact,
    emergencyContactRelation: data?.emergencyContactRelation,
    designation: data?.designation,
    role: data?.role,
    joiningDate: data?.joiningDate,
    employeeType: data?.employeeType,
    jobType: data?.jobType,
    jobLocation: data?.jobLocation,
    basicSalary: data?.basicSalary,
    homeAllowance: data?.homeAllowance,
    medicalAllowance: data?.medicalAllowance,
    transportationAllowance: data?.transportationAllowance,
    casualLeave: data?.casualLeave,
    sickLeave: data?.sickLeave,
    advancedSalaryEligibility: data?.advancedSalaryEligibility,
    loanSalaryEligibility: data?.loanSalaryEligibility,
    casualLeaveEligibility: data?.casualLeaveEligibility,
    havingLunch: data?.havingLunch,
    overtimeEligibility: data?.overtimeEligibility,
    deptId: data?.deptId,
    branchId: data?.branchId,
    laptop: data?.laptop,
    mobile: data?.mobile,
    mouse: data?.mouse,
    keyboard: data?.keyboard,
    headphone: data?.headphone,
    otherAllocation: data?.otherAllocation,
  });

  console.log(formData);

  return (
    <Box>
      <Box ml={1}>
        <Typography variant="title" className="page-title">
          Update Employee Details
        </Typography>
      </Box>

      <Box sx={{ mt: 4 }}>
        <UpdateEmployeeTabs
          data={data}
          formData={formData}
          setFormData={setFormData}
        />
      </Box>
    </Box>
  );
};

export default UpdateEmployee;
