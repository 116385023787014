import React, { useEffect } from "react";
import Attendance from "./pages/Attendance/Attendance";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashboardSidebar from "./components/DashboardSidebar/DashboardSidebar";
import Employee from "./pages/Employee/Employee";
import Department from "./pages/Department/Department";
import Manage from "./pages/Manage/Manage";
import SignIn from "./pages/SignIn/SignIn";
import SingleOfficeAttendence from "./pages/OfficeAttendence/SingleOfficeAttendence";
import SingleStaffAttendence from "./pages/OfficeAttendence/SingleStaffAttendence";
import AddEmployee from "./pages/Employee/AddEmployee/AddEmployee";
import AddDepartment from "./pages/Department/AddDepartment";
import Roster from "./pages/Roaster/Roster";
import AddRoster from "./pages/Roaster/AddRoster";
import EmployeeDetails from "./pages/Employee/EmployeeDetails/EmployeeDetails";
import Notice from "./pages/Notice/Notice";
import Leave from "./pages/Leave/Leave";
import Office from "./pages/Office/Office";
import AddOffice from "./pages/Office/AddOffice";
import Loan from "./pages/Loan/Loan";
import AdvanceSalary from "./components/AdvanceSalary/AdvanceSalary";
import ProvidentFund from "./pages/ProvidentFund/ProvidentFund";
import AdminPrivateRoute from "./pages/AdminPrivateRoute/AdminPrivateRoute";
import EmployeeLeave from "./pages/Leave/EmployeeLeave";
import EmployeeLeaveDetails from "./pages/Leave/EmployeeLeaveDetails";
import EmployeeLeaveRequest from "./pages/Leave/EmployeeLeaveRequest";
import Test from "./Test";
import AddNotice from "./pages/Notice/AddNotice";
import UpdateEmployee from "./pages/Employee/UpdateEmployee/UpdateEmployee";
import EditRoaster from "./pages/Employee/EditRoaster/EditRoaster";
import Holiday from "./components/Holiday/Holiday";
import AbsentRules from "./pages/Rules/AbsentRules";
import SetAbsentRules from "./pages/Rules/SetAbsentRules";
import LateCountRules from "./pages/Rules/LateCountRules";
import SetLateCountRules from "./pages/Rules/SetLateCountRules";
import AddManager from "./pages/Department/AddManager";
import DepartmentManagerList from "./pages/Department/DepartmentManagerList";
import ActivityLog from "./pages/ActivityLog/ActivityLog";
import OTPverification from "./pages/OTPverification/OTPverification";
import OverTime from "./pages/OverTime/OverTime";
import AddOverTime from "./pages/OverTime/AddOverTime";
import Home from "./pages/Home/Home";
import TermsAndCon from "./pages/TermsAndCon/TermsAndCon";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import SalaryBuild from "./pages/SalaryBuild/SalaryBuild";
import EmployeeSalary from "./pages/Salary/EmployeeSalary";
import OfficeSalary from "./pages/Salary/OfficeSalary";
import DeptSalary from "./pages/Salary/DeptSalary";
import ExEmployee from "./pages/Employee/ExEmployee";
import ReviewSalary from "./pages/ReviewSalary/ReviewSalary";
import PendingOperations from "./pages/PendingOperations/PendingOperations";
import AssignShift from "./pages/Employee/AssignShift/AssignShift";
import UpdateShifts from "./pages/UpdateShifts/UpdateShifts";

const App = () => {
  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= 5 && hours < 11) {
      // blue
      document.documentElement.style.setProperty("--primary-color", "#6fabf2");
      document.documentElement.style.setProperty("--dark-pr-clr", "#4C91E2");
      document.documentElement.style.setProperty("--light-pr-clr", "#C2DEFF ");
    } else if (hours >= 11 && hours < 15) {
      // orange
      document.documentElement.style.setProperty("--primary-color", "#ff8717");
      document.documentElement.style.setProperty("--dark-pr-clr", "#D36B0B");
      document.documentElement.style.setProperty("--light-pr-clr", "#FFE9D6 ");
    } else if (hours >= 15 && hours < 20) {
      // green
      document.documentElement.style.setProperty("--primary-color", "#61a143");
      document.documentElement.style.setProperty("--dark-pr-clr", "#158933");
      document.documentElement.style.setProperty("--light-pr-clr", "#C6DEBB");
    } else {
      // dark blue
      document.documentElement.style.setProperty("--primary-color", "#04435f");
      document.documentElement.style.setProperty("--dark-pr-clr", "#13668C");
      document.documentElement.style.setProperty("--light-pr-clr", "#B1E2F9");
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/otpVerification" element={<OTPverification />} />
        <Route path="/termsAndConditions" element={<TermsAndCon />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

        <Route
          path="/dashboard"
          element={
            <AdminPrivateRoute>
              <DashboardSidebar />
            </AdminPrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />

          <Route path="/dashboard/employee" element={<Employee />} />
          <Route path="/dashboard/exemployee" element={<ExEmployee />} />

          <Route
            path="/dashboard/employeeUpdate"
            element={<UpdateEmployee />}
          />
          <Route
            path="/dashboard/employee/editRoaster"
            element={<EditRoaster />}
          />
          <Route
            path="/dashboard/employee/assignShift"
            element={<AssignShift />}
          />
          <Route
            path="/dashboard/employeeDtails"
            element={<EmployeeDetails />}
          />
          <Route
            path="/dashboard/employee/addEmployee"
            element={<AddEmployee />}
          />

          <Route path="/dashboard/officeSalary" element={<OfficeSalary />} />
          <Route path="/dashboard/reviewSalary" element={<ReviewSalary />} />
          <Route path="/dashboard/deptSalary" element={<DeptSalary />} />
          <Route
            path="/dashboard/employeeSalary"
            element={<EmployeeSalary />}
          />
          <Route path="/dashboard/department" element={<Department />} />
          <Route
            path="/dashboard/department/addDepartment"
            element={<AddDepartment />}
          />
          <Route
            path="/dashboard/department/departmentManagerList"
            element={<DepartmentManagerList />}
          />
          <Route
            path="/dashboard/department/addManager"
            element={<AddManager />}
          />
          <Route path="/dashboard/attendance" element={<Attendance />} />
          <Route path="/dashboard/roster" element={<Roster />} />
          <Route path="/dashboard/roster/addRoster" element={<AddRoster />} />
          <Route path="/dashboard/updateShifts" element={<UpdateShifts />} />
          <Route
            path="/dashboard/singleOfficeAttendance"
            element={<SingleOfficeAttendence />}
          />
          <Route
            path="/dashboard/singleStaffAttendance"
            element={<SingleStaffAttendence />}
          />
          <Route path="/dashboard/salaryBuild" element={<SalaryBuild />} />
          <Route path="/dashboard/manage" element={<Manage />} />
          <Route path="/dashboard/notice" element={<Notice />} />
          <Route path="/dashboard/notice" element={<Notice />} />
          <Route path="/dashboard/holiday" element={<Holiday />} />
          <Route path="/dashboard/addNotice" element={<AddNotice />} />
          <Route path="/dashboard/leave" element={<Leave />} />
          <Route
            path="/dashboard/pendingOperations"
            element={<PendingOperations />}
          />
          <Route path="/dashboard/employeeLeave" element={<EmployeeLeave />} />
          <Route
            path="/dashboard/employeeLeaveRequest"
            element={<EmployeeLeaveRequest />}
          />
          <Route
            path="/dashboard/employeeLeaveDetails"
            element={<EmployeeLeaveDetails />}
          />
          <Route path="/dashboard/office" element={<Office />} />
          <Route path="/dashboard/addOffice" element={<AddOffice />} />
          <Route path="/dashboard/absentRules" element={<AbsentRules />} />
          <Route
            path="/dashboard/lateCountRules"
            element={<LateCountRules />}
          />
          <Route
            path="/dashboard/setAbsentRules"
            element={<SetAbsentRules />}
          />
          <Route
            path="/dashboard/setLateCountRules"
            element={<SetLateCountRules />}
          />
          <Route path="/dashboard/loan" element={<Loan />} />
          <Route path="/dashboard/advanceSalary" element={<AdvanceSalary />} />
          <Route path="/dashboard/providentFund" element={<ProvidentFund />} />
          <Route path="/dashboard/overTime" element={<OverTime />} />
          <Route path="/dashboard/addOverTime" element={<AddOverTime />} />
          <Route path="/dashboard/activityLog" element={<ActivityLog />} />
        </Route>

        <Route path="/test" element={<Test />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
