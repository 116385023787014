import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { submenuButton, addButton } from "../../utility/styles";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import APILoader from "../../components/APILoader/APILoader";
import Swal from "sweetalert2";

const headers = [
  "Name",
  "Status",
  "Roaster",
  "Designations",
  "Join date",
  "Salary",
  "Leave",
  "Personal Contact",
  "Action",
  "Active",
];

const Employee = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("admin-info");
  const [departments, setDepartments] = useState([]);
  const [deptId, setDeptId] = useState([]);
  const [employeeDept, setEmployeeDept] = useState([]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allCheck, setAllCheck] = useState(false);
  const token = user?.accessToken;

  const handleUpdate = (data) => {
    const dataToSend = {
      data,
    };

    navigate("/dashboard/employeeUpdate", { state: { data: dataToSend } });
  };

  const handleMasterCheckboxChange = (event) => {
    let isChecked = event.target.checked;

    if (allCheck === false) {
      isChecked = true;
      setAllCheck(true);
    } else {
      setAllCheck(false);
      isChecked = false;
    }

    if (isChecked) {
      const allselectedEmployee = employeeDept.map((data) => ({
        id: data.id,
        firstname: data.firstname,
        lastname: data.lastname,
        deptName: data.deptName,
        branchId: data.branchId,
        deptId: data.deptId,
      }));
      setSelectedRows(allselectedEmployee);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSingleCheckboxChange = (
    event,
    id,
    firstname,
    lastname,
    deptName,
    branchId,
    deptId
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows((prevselectedEmployee) => [
        ...prevselectedEmployee,
        { id, firstname, lastname, deptName, branchId, deptId },
      ]);
    } else {
      setSelectedRows((prevselectedEmployee) =>
        prevselectedEmployee.filter((row) => row.id !== id)
      );
    }
  };

  useEffect(() => {
    const token = user?.accessToken;
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/departments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setDepartments(data?.data);
        handleButtonClick(0, data?.data[0]?.id);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, []);

  useEffect(() => {
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/v2/company/employees?deptId=${deptId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setEmployeeDept(
          data?.data.map((emp) => ({
            ...emp,
            isActive: emp.status === "active" ? true : false,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, [deptId]);

  const handleButtonClick = (index, id) => {
    setSelectedButtonIndex(index);
    setDeptId(id);
    setAllCheck(false);
    setSelectedRows([]);
  };

  const handleSingleEmployee = (id) => {
    navigate(`/dashboard/employeeDtails`, { state: { employeeId: id } });
  };

  const handleStatusChange = (id) => {
    const singleUpdated = employeeDept.find((emp) => emp.id === id);

    try {
      Swal.fire({
        icon: "warning",
        title: `Are you sure you want to ${
          singleUpdated.isActive ? "inactive" : "active"
        } employee`,
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Ok",
      }).then(async function (res) {
        if (res.isConfirmed) {
          await fetch(
            `https://flyfarerp-413411.de.r.appspot.com/api/company/employees/${id}/update-status`,
            {
              method: "PATCH",
              body: JSON.stringify({
                status: singleUpdated.isActive ? "inactive" : "active",
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.success) {
                Swal.fire({
                  icon: "success",
                  title: data.message,
                  confirmButtonColor: "var(--primary-color)",
                  confirmButtonText: "Ok",
                }).then(function () {
                  window.location.reload();
                });
              }
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ position: "relative", height: "100vh", overflowY: "auto" }}>
      {/*------------- Page Title -------------*/}

      {departments.length > 0 ? (
        <Box sx={{ paddingBottom: "50px" }}>
          <Box sx={{ display: "flex", gap: "25px" }}>
            <Typography variant="title" className="page-title">
              Employee List
            </Typography>
          </Box>

          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                flexWrap: "wrap",
                width: "75%",
              }}
            >
              {departments.map((department, i) => (
                <Button
                  key={i}
                  sx={{
                    ...submenuButton,
                    ...(i === selectedButtonIndex && {
                      backgroundColor: "var(--primary-color)",
                      color: "#ffffff",
                      ":hover": {
                        bgcolor: "var(--primary-color)",
                      },
                      // width: "100px",
                    }),
                  }}
                  onClick={() => handleButtonClick(i, department.id)}
                >
                  {department?.deptName}
                </Button>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Button
                sx={{
                  background: "var(--primary-color)",
                  color: "#ffffff",
                  borderRadius: "5px",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "var(--primary-color)",
                  },
                  visibility: selectedRows.length > 0 ? "visible" : "hidden",
                }}
                onClick={() => {
                  navigate("/dashboard/employee/assignShift", {
                    state: { selectedRows },
                  });
                }}
              >
                Assign Shifts
              </Button>
              {/* <Button
                sx={{
                  background: "var(--primary-color)",
                  color: "#ffffff",
                  borderRadius: "5px",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "var(--primary-color)",
                  },
                  visibility: selectedRows.length > 0 ? "visible" : "hidden",
                }}
                onClick={() => {
                  navigate("/dashboard/employee/editRoaster", {
                    state: { selectedRows },
                  });
                }}
              >
                Edit Roaster
              </Button> */}

              <Button
                onClick={() => navigate("/dashboard/employee/addEmployee")}
                sx={addButton}
              >
                Add Employee
              </Button>
            </Box>
          </Box>

          {/*------------- Page Content -------------*/}
          <Box mt={3}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1450 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={allCheck}
                        onChange={handleMasterCheckboxChange}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "Var(--primary-color)", // Change to the desired color
                          },
                        }}
                      />
                    </TableCell>
                    {headers.map((header, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeeDept.map((data, index) => {
                    // console.log(data)
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.some(
                              (row) => row.id === data.id
                            )}
                            onChange={(event) =>
                              handleSingleCheckboxChange(
                                event,
                                data.id,
                                data.firstname,
                                data.lastname,
                                data.deptName,
                                data.branchId,
                                data.deptId
                              )
                            }
                            sx={{
                              "& .MuiSvgIcon-root": {
                                color: "Var(--primary-color)", // Change to the desired color
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            sx={{
                              backgroundColor: "var(--primary-color)",
                              color: "#ffffff",
                              ":hover": {
                                bgcolor: "var(--primary-color)",
                              },
                              width: "80%",
                            }}
                            onClick={() => handleSingleEmployee(data?.id)}
                          >
                            {data?.firstname} {data?.lastname}{" "}
                          </Button>
                        </TableCell>
                        <TableCell> {data?.status} </TableCell>
                        <TableCell>
                          {data?.shifts?.map((data) => (
                            <p style={{ padding: "0px", lineHeight: "5px" }}>
                              {data?.roasterName}
                            </p>
                          ))}
                        </TableCell>
                        <TableCell> {data?.designation} </TableCell>
                        <TableCell> {data?.joiningDate} </TableCell>
                        <TableCell> {data?.grossSalary} </TableCell>
                        <TableCell> {data?.casualLeave} </TableCell>
                        <TableCell> {data?.personalPhone} </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{
                              marginLeft: "10px",
                              background: "var(--primary-color)",
                              "&:hover": {
                                background: "var(--primary-color)",
                              },
                            }}
                            onClick={() => handleUpdate(data)}
                          >
                            <Typography
                              sx={{ fontSize: "12px", color: "white" }}
                            >
                              Update
                            </Typography>
                          </Button>
                        </TableCell>

                        <TableCell>
                          <Switch
                            checked={data?.isActive}
                            onChange={() => handleStatusChange(data.id)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)" }}>
          <APILoader />
        </Box>
      )}
    </Box>
  );
};

export default Employee;
