export const submenuButton = {
  bgcolor: "#EFF2F5",
  ":hover": {
    bgcolor: "#EFF2F5",
  },
  textTransform: "capitalize",
  color: "var(--text-medium)",
  fontSize: "12px",
  padding: "4px 8px",
};

export const addButton = {
  bgcolor: "var(--text-medium)",
  ":hover": {
    bgcolor: "var(--text-medium)",
  },
  textTransform: "capitalize",
  color: "white",
  fontSize: "12px",
  width: "120px",
  padding: "5px 8px",
};

export const inactiveButton = {
  bgcolor: "#EFF2F5",
  ":hover": {
    bgcolor: "#EFF2F5",
  },
  textTransform: "capitalize",
  color: "var(--text-medium)",
  fontSize: "12px",
  padding: "4px 8px",
};
