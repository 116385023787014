import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const localApi = "http://192.168.1.103:5000/api/company/";
// const localApiV2 = "http://192.168.1.103:5000/api/v2/company/";
const liveApi = "https://flyfarerp-413411.de.r.appspot.com/api/company/";
// const liveApiV2 = "https://flyfarerp-413411.de.r.appspot.com/api/v2/company/";
const user = secureLocalStorage.getItem("admin-info");
const token = user?.accessToken;

const headers = {
  Authorization: `Bearer ${token}`,
  "Content-Type": "application/json",
};

export const getFetcher = async (endPoint) => {
  const url = liveApi + endPoint;

  try {
    const reponse = await axios.get(url, { headers });

    return reponse?.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const getFetcherLocal = async (endPoint) => {
  const url = localApi + endPoint;

  try {
    const reponse = await axios.get(url, { headers });

    return reponse?.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const postFetcher = async ({ endPoint, body }) => {
  const url = liveApi + endPoint;

  try {
    const reponse = await axios.post(url, JSON.stringify(body), { headers });

    return reponse?.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const patchFetcher = async ({ endPoint, body }) => {
  const url = liveApi + endPoint;

  try {
    const reponse = await axios.patch(url, JSON.stringify(body), { headers });

    return reponse?.data;
  } catch (e) {
    return e?.response?.data;
  }
};
export const putFetcher = async ({ endPoint, body }) => {
  const url = liveApi + endPoint;

  try {
    const reponse = await axios.put(url, JSON.stringify(body), { headers });

    return reponse?.data;
  } catch (e) {
    return e?.response?.data;
  }
};
