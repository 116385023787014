import {
  Box,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

import { styled } from "@mui/material/styles";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import DashboardTabs from "./DashboardTabs";
import zIndex from "@mui/material/styles/zIndex";
const domain = [
  0,
  (dataMax) => {
    const a = parseInt("9".toString().repeat(String(dataMax).length - 1)) + 1;
    return Math.ceil(dataMax / a) * a;
  },
];

const Dashboard = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState([]);
  const [employeeDataBranch, setEmployeeDataBranch] = useState([]);
  const [employeeDataDept, setEmployeeDataDept] = useState([]);
  const [barChart, setBarChartData] = useState([]);

  const user = secureLocalStorage.getItem("admin-info");
  const token = user?.accessToken;
  const [office, setOffice] = useState([]);
  const [id, setId] = useState([]);

  const formDate = new Date();
  const currentDateTimestamp = formDate.getTime();
  const fiveDaysAgoTimestamp = currentDateTimestamp - 5 * 24 * 60 * 60 * 1000;
  const fiveDaysAgoDate = new Date(fiveDaysAgoTimestamp);
  const toDate = new Date();

  const pieChartData = [
    {
      name: "Not Check In",
      uv: Number(attendanceData?.checkInPending),
      pv: 2400,
      amt: 2400,
    },

    {
      name: "Check In",
      uv: Number(attendanceData?.checkInTotal),
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Not Check Out",
      uv: Number(attendanceData?.checkOutPending),
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Check Out",
      uv: Number(attendanceData?.checkOutTotal),
      pv: 1398,
      amt: 2210,
    },
    {
      name: "On Leave",
      uv: Number(attendanceData?.leaves),
      pv: 1398,
      amt: 2210,
    },
    {
      name: "On Weekends",
      uv: Number(attendanceData?.weekends),
      pv: 1398,
      amt: 2210,
    },
  ];

  const barChartData = barChart.map((data, index) => ({
    name: data?.date,
    pv: Number(data.onTimeCheckIns),
    uv: Number(data.noCheckIns),
    amt: Number(data.lateCheckIns),
  }));

  useEffect(() => {
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/dashboard/attendance-stats?date=${moment(
        currentDate
      ).format("YYYY-MM-DD")}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch data");
        }
        return res.json();
      })
      .then((data) => {
        setAttendanceData(data?.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const token = user?.accessToken;
    fetch(
      `https://flyfarerp-413411.de.r.appspot.com/api/company/dashboard/attendance-summary?date=${moment(
        currentDate
      ).format("YYYY-MM-DD")}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch data");
        }
        return res.json();
      })
      .then((data) => {
        setEmployeeDataBranch(data?.data?.branches[0]);
        setEmployeeDataDept(data?.data?.branchDepts[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`https://flyfarerp-413411.de.r.appspot.com/api/company/branches`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch departments");
        }
        return res.json();
      })
      .then((data) => {
        setOffice(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
      });
  }, [token]);

  // bar chart data fecth
  // fiveDaysAgoDate;
  // toDate;
  useEffect(() => {
    const url = `https://flyfarerp-413411.de.r.appspot.com/api/company/dashboard/attendance-summary-by-day?branchId=20f52462a0954b8081a79b54d921a264&fromDate=${moment(
      fiveDaysAgoDate
    ).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch data");
        }
        return res.json();
      })
      .then((data) => {
        setBarChartData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  //---- For PieChart----//
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const handleResize = (entry) => {
    setDimensions({ width: entry.width, height: entry.height });
  };

  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));

  function PieCenterLabel({ width, height }) {
    const x = width / 2;
    const y = height / 2;

    return (
      <StyledText x={x} y={y}>
        Total Employee
      </StyledText>
    );
  }

  //---- For BarChart----//

  const seriesA = {
    data: [2, 3, 1, 4, 5],
    label: "Series A",
  };
  const seriesB = {
    data: [3, 1, 4, 2, 1],
    label: "Series B",
  };
  const seriesC = {
    data: [3, 2, 4, 5, 1],
    label: "Series C",
  };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <Box
      sx={{
        "& .MuiOutlinedInput-root": {
          paddingLeft: 2,
          // minWidth: 250,
          width: "100%",
        },
      }}
    >
      <Grid container sx={{ height: "100vh" }}>
        <Grid item xs={4} sm={4} md={9}>
          <Box>
            {/* pie chart */}
            <Grid container px={1} sx={{ height: "280px" }}>
              <Grid
                item
                xs={4}
                sm={4}
                md={3}
                sx={{
                  background: "#F4F5F7",
                  borderRadius: "8px",
                  height: "250px",
                  // mt: 2,
                }}
              >
                <Box
                  sx={{ height: "100%", position: "relative", zIndex: "1000" }}
                >
                  {/* <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        dataKey="uv"
                        cx="50%"
                        cy="50%"
                        innerRadius={70}
                        outerRadius={90}
                        fill="#82ca9d"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer> */}

                  <Typography
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                      fontSize: "14px",
                      lineHeight: "1.2",
                      zIndex: "-1",
                    }}
                  >
                    Total <br /> Employees <br />{" "}
                    <span style={{ fontSize: "2rem" }}>
                      {attendanceData?.totalAttendances}{" "}
                    </span>
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={8.9}
                sx={{
                  background: "var(--bg-color)",
                  borderRadius: "8px",
                  height: "250px",
                  // mt: 2,
                  ml: 1,
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "200px",
                    gap: 1,

                    px: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      background: "#ffffff",
                      borderRadius: "5px",
                      width: "220px",
                      height: "150px",
                      p: 2,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#DBE9F9",
                        width: "40px",
                        height: "40px",
                        mb: 1,
                        mt: 2,
                        borderRadius: "5px",
                      }}
                    ></Box>
                    <Typography
                      sx={{ fontSize: "10px", color: "var(--text-color)" }}
                    >
                      Checked In
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "30px",
                        color: "#5B9AE4",
                        fontWeight: 500,
                      }}
                    >
                      {attendanceData?.checkInTotal}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      background: "#ffffff",
                      borderRadius: "5px",
                      width: "220px",
                      height: "150px",
                      p: 2,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#DBE9F9",
                        width: "40px",
                        height: "40px",
                        mb: 1,
                        mt: 2,
                        borderRadius: "5px",
                      }}
                    ></Box>
                    <Typography
                      sx={{ fontSize: "10px", color: "var(--text-color)" }}
                    >
                      Not Checked In
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "30px",
                        color: "#FF6867",
                        fontWeight: 500,
                      }}
                    >
                      {attendanceData?.checkInPending}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      background: "#ffffff",
                      borderRadius: "5px",
                      width: "220px",
                      height: "150px",

                      p: 2,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#DBE9F9",
                        width: "40px",
                        height: "40px",
                        mb: 1,
                        mt: 2,
                        borderRadius: "5px",
                      }}
                    ></Box>
                    <Typography
                      sx={{ fontSize: "10px", color: "var(--text-color)" }}
                    >
                      On Leave
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "30px",
                        color: "#34C75A",
                        fontWeight: 500,
                      }}
                    >
                      {attendanceData?.leaves}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      background: "#ffffff",
                      borderRadius: "5px",
                      width: "220px",
                      height: "150px",
                      p: 2,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#DBE9F9",
                        width: "40px",
                        height: "40px",
                        mb: 1,
                        mt: 2,
                        borderRadius: "5px",
                      }}
                    ></Box>
                    <Typography
                      sx={{ fontSize: "10px", color: "var(--text-color)" }}
                    >
                      Weekend Off
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "30px",
                        color: "#4D4B4B",
                        fontWeight: 500,
                      }}
                    >
                      {attendanceData?.weekends}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      background: "#ffffff",
                      borderRadius: "5px",
                      width: "220px",
                      height: "150px",
                      p: 2,
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#DBE9F9",
                        width: "40px",
                        height: "40px",
                        mb: 1,
                        mt: 2,
                        borderRadius: "5px",
                      }}
                    ></Box>
                    <Typography
                      sx={{ fontSize: "10px", color: "var(--text-color)" }}
                    >
                      Checked Out
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "30px",
                        color: "#FF8617",
                        fontWeight: 500,
                      }}
                    >
                      {attendanceData?.checkOutTotal}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* bar chart */}
            <Grid container p={1} sx={{ height: "250px" }}>
              <Grid
                item
                xs={4}
                sm={4}
                md={5.9}
                sx={{
                  background: "#F4F5F7",
                  // mt: 1,
                  height: "100%",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", ml: "20px", my: 2 }}>
                    Check In On time Statistics
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      ml: "20px",
                      bgcolor: "var(--primary-color)",
                      borderRadius: "5px",
                      px: 2,
                      py: 0.5,
                      color: "white",
                      mr: "10px",
                    }}
                  >
                    {moment(fiveDaysAgoDate).format("Do MMM")} -{" "}
                    {moment(toDate).format("Do MMM")}
                  </Typography>
                </Box>

                <Box sx={{ height: "170px" }}>
                  {/* <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={barChartData}
                      margin={{
                        left: 10,
                        right: 10,
                      }}
                    >
                      <CartesianGrid
                        strokeDasharray="5 3"
                        // stroke={
                        //   theme.palette.mode === "dark" ? "#ffffff20" : "#dadce0"
                        // }
                      />
                      <XAxis
                        dataKey="name"
                        axisLine={{ stroke: "none" }}
                        tickLine={false}
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis
                        domain={domain}
                        tickCount={8}
                        tickFormatter={(value) => `${value}`}
                        axisLine={{ stroke: "none" }}
                        tickLine={false}
                        tick={{ fontSize: 12 }}
                      />

                      <Bar
                        dataKey="pv"
                        fill="#FFA84D"
                        maxBarSize={10}
                        radius={[10, 10, 0, 0]}
                      />
                      <Bar
                        dataKey="uv"
                        fill="#EC1F28"
                        maxBarSize={10}
                        radius={[10, 10, 0, 0]}
                      />
                      <Bar
                        dataKey="amt"
                        fill="#00C49F"
                        maxBarSize={10}
                        radius={[10, 10, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer> */}
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={5.9}
                sx={{
                  background: "var(--bg-color)",
                  p: 2,
                  borderRadius: "8px",
                  height: "235px",

                  ml: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    background: "#ffffff",

                    width: "50%",
                    height: "170px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",

                      height: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ fontSize: "11px", color: "var(--text-color)" }}
                      >
                        Total Employee
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          my: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#34C75A",
                            fontSize: "35px",
                            fontWeight: 500,
                          }}
                        >
                          {employeeDataBranch?.totalEmployees}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 3 }}>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Pending
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Check In
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Check In Late
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Check Out
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Check Out Early
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Absent
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Late
                            </Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataBranch?.noCheckIns}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataBranch?.totalCheckIns}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataBranch?.lateCheckIns}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataBranch?.totalCheckOuts}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataBranch?.earlyCheckOuts}
                            </Typography>

                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataBranch?.lateCheckIns}
                            </Typography>

                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataBranch?.lateCheckIns}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Typography sx={{ fontSize: "11px", color: "#5B9AE4" }}>
                      {employeeDataBranch?.branchName} Branch
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: 2,
                    background: "#ffffff",
                    width: "50%",
                    height: "170px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ fontSize: "11px", color: "var(--text-color)" }}
                      >
                        Total Employee
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          my: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#34C75A",
                            fontSize: "35px",
                            fontWeight: 500,
                          }}
                        >
                          {employeeDataDept?.totalEmployees}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Pending
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Check In
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Check In Late
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Check Out
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Check Out Early
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Absent
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                color: "var(--text-color)",
                              }}
                            >
                              Late
                            </Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataDept?.noCheckIns}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataDept?.onTimeCheckIns}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataDept?.lateCheckIns}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataDept?.onTimeCheckOuts}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataDept?.earlyCheckOuts}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataDept?.incompleteAttendances}
                            </Typography>
                            <Typography sx={{ fontSize: "11px" }}>
                              {employeeDataDept?.lateCheckIns}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Typography sx={{ fontSize: "11px", color: "#5B9AE4" }}>
                      {employeeDataDept?.deptName} Department
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container p={1}>
              <Grid
                item
                xs={4}
                sm={4}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "var(--bg-color)",
                  p: 2,
                  borderRadius: "8px",
                  gap: 2,
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    background: "#ffffff",
                    borderRadius: "5px",
                    p: 2,
                    width: "30%",
                    height: "80px",
                  }}
                >
                  {" "}
                  <Typography sx={{ fontSize: "12px" }}>Late Coming</Typography>
                  <Typography sx={{ fontSize: "30px", color: "#6FABF2" }}>
                    {attendanceData?.checkInLate}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: "#ffffff",
                    borderRadius: "5px",
                    p: 2,
                    width: "30%",
                    height: "80px",
                  }}
                >
                  {" "}
                  <Typography sx={{ fontSize: "12px" }}>Early Going</Typography>
                  <Typography sx={{ fontSize: "30px", color: "#6FABF2" }}>
                    {attendanceData?.checkOutEarly}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: "#ffffff",
                    borderRadius: "5px",
                    p: 2,
                    width: "30%",
                    height: "80px",
                  }}
                >
                  {" "}
                  <Typography sx={{ fontSize: "12px" }}>
                    Leave Request
                  </Typography>
                  <Typography sx={{ fontSize: "30px", color: "#6FABF2" }}>
                    {attendanceData?.totalLeaveRequests}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          item
          xs={4}
          sm={4}
          md={3}
          sx={{
            background: "var(--bg-color)",
            p: 1,
            borderRadius: "8px",
          }}
        >
          <Box>
            {/* <Select
              // value={selectedOffice}
              // onChange={handleSelectOffice}
              sx={{
                color: "black",
                "& .MuiSelect-icon": {
                  color: "black",
                },

                "& .MuiSelect-select:focus": {
                  backgroundColor: "transparent",
                  border: "none",
                },
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiSelect-select:focus": {
                  border: "10px",
                },
                bgcolor: "var(--primary-color)",
                color: "white",
              }}
            >
              <MenuItem value="" disabled>
                Select Office
              </MenuItem>
              {office?.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.branchName}
                </MenuItem>
              ))}
            </Select> */}
            <DashboardTabs office={office} currentDate={currentDate} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
