import React, { useState } from "react";

import axios from "axios";
import { useQuery } from "react-query";
import { getFetcher } from "./utility/fetcher";

const baseUrl = "https://flyfarerp-413411.de.r.appspot.com";

// Function to fetch filter data
const fetchFilterData = async () => {
  const { data } = await axios.get(`${baseUrl}/api/company/requests`);
  return data;
};

// Function to fetch data based on selected module
const fetchData = async ({ queryKey }) => {
  const [_key, { route, queries }] = queryKey;
  const queryParams = new URLSearchParams(queries).toString();
  const fullUrl = `${baseUrl}${route}?${queryParams}`;
  const { data } = await axios.get(fullUrl);
  return data;
};

const Test = () => {
  const [selectedModule, setSelectedModule] = useState(null);

  // Fetch the filterData from the API
  const {
    data: filterData,
    isLoading: isFilterLoading,
    error: filterError,
  } = useQuery({
    queryKey: ["pendingRequests"],
    queryFn: () => getFetcher("requests"),
  });

  // Fetch the data based on the selected module
  const { data, isLoading, error } = useQuery(
    [
      "filterButtons",
      filterData?.data?.find((item) => item.module === selectedModule),
    ],
    fetchData,
    {
      enabled: !!selectedModule, // Only fetch data when a module is selected
    }
  );

  const handleClick = (module) => {
    setSelectedModule(module);
  };

  if (isFilterLoading) return <p>Loading filters...</p>;
  if (filterError) return <p>Error loading filters</p>;


// console.log(data)

  return (
    <div>
      <div>
        {filterData?.data?.map((item, index) => (
          <button
            key={index}
            onClick={() => handleClick(item.module)}
            style={{
              backgroundColor: selectedModule === item.module ? "blue" : "gray",
              color: "white",
              margin: "5px",
            }}
          >
            {item.module} ({item.pending})
          </button>
        ))}
      </div>

      <div>
        {isLoading ? (
          <p>Loading data...</p>
        ) : error ? (
          <p>Error loading data</p>
        ) : (
          <div>
            <h3>Data for {selectedModule}:</h3>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default Test;
