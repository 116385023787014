import { Box, Typography, Button, Collapse, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as HomeBlobIcon } from "../../assets/svg/homeblob.svg";
import { ReactComponent as PlayIcon } from "../../assets/svg/googleplay.svg";
import { ReactComponent as AppleIcon } from "../../assets/svg/apple.svg";
import { ReactComponent as PhoneIcon } from "../../assets/svg/phone.svg";
import { ReactComponent as LogoIcon } from "../../assets/svg/logo.svg";
import { ReactComponent as TickIcon } from "../../assets/svg/tickmark.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/homeuser.svg";
import { ReactComponent as HomeComputerIcon } from "../../assets/svg/homeComputer.svg";
import { ReactComponent as WaterPlanIcon } from "../../assets/svg/waterplane.svg";
import { ReactComponent as TelephoneIcon } from "../../assets/svg/telephone.svg";
import { ReactComponent as FbIcon } from "../../assets/svg/fb.svg";
import { ReactComponent as InstaIcon } from "../../assets/svg/insta.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/svg/linkedin.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/twitter.svg";
import { ReactComponent as FFTLogo } from "../../assets/svg/FFTlogo.svg";
import moment from "moment/moment";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const primaryButton = {
  color: "white",
  textTransform: "capitalize",
  bgcolor: "var(--dark-pr-clr)",
  ":hover": { bgcolor: "var(--dark-pr-clr)" },
  border: "1px solid var(--dark-pr-clr)",
  minWidth: "130px",
};

const styles = { flexCenter: { display: "flex", alignItems: "center" } };

const appStoreBtn = {
  ...primaryButton,
  height: "45px",
  display: "flex",
  gap: "10px",
};

const fontBold = { fontFamily: "productSans500 !important" };

const sectionTitle = { fontSize: "70px", ...fontBold };

const Home = () => {
  return (
    <Box sx={{ bgcolor: "var(--primary-color)", height: "100%" }}>
      <Navbar />
      <Header />

      <Box
        sx={{ mt: "-250px", bgcolor: "white", borderRadius: "150px 150px 0 0" }}
      >
        <About />
        <Attendance />
        <EasyTrack />
        <Features />
        <Pricing />
        <Box
          sx={{
            bgcolor: "var(--primary-color)",
            borderRadius: "150px 150px 0 0",
          }}
        >
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

const Header = () => {
  const [welcomeMessage, setWelcomeMessage] = useState("Morning");

  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= 5 && hours < 11) {
      setWelcomeMessage("Morning");
    } else if (hours >= 11 && hours < 15) {
      setWelcomeMessage("After Noon");
    } else if (hours >= 15 && hours < 20) {
      setWelcomeMessage("Evening");
    } else {
      setWelcomeMessage("Night");
    }
  }, []);
  return (
    <Box
      sx={{ display: "flex", mt: 2.5, maxWidth: "1400px", margin: "0 auto" }}
    >
      <Box sx={{ width: "70%", mt: 5, height: "max-content" }}>
        <Typography sx={{ fontSize: "47px", color: "white" }}>
          Unlock the potential of your organization with HRM software
        </Typography>
        <Box sx={{ display: "flex", gap: "15px", mt: 8 }}>
          <Button sx={appStoreBtn}>
            <PlayIcon /> Google Play Store
          </Button>
          <Button sx={appStoreBtn}>
            <AppleIcon /> Apple Store
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "30%", position: "relative" }}>
        <HomeBlobIcon
          fill="var(--dark-pr-clr)"
          style={{ position: "absolute", right: "-40px" }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 1000,
            left: "2vw",
            top: "10vh",
            width: "max-content",
          }}
        >
          <PhoneIcon />
          <Box
            sx={{
              position: "absolute",
              zIndex: 1000,
              top: "52px",
              left: "42px",
            }}
          >
            <Typography sx={{ fontSize: "17px", color: "white", ...fontBold }}>
              Good {welcomeMessage} Employee !
            </Typography>
            <Typography sx={{ color: "white", fontSize: "14.5px" }}>
              {moment().format("dddd, DD MMMM YYYY")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const About = () => {
  const [open, setOpen] = useState(null);
  return (
    <Box sx={{ maxWidth: "1400px", margin: "0 auto" }}>
      <Box sx={{ width: "50%" }}>
        <Typography
          sx={{
            fontSize: "22px",
            color: "var(--text-medium)",
            ...fontBold,
            pt: 5,
            pb: 3,
          }}
        >
          Effortless Attendance Management with a Single Click
        </Typography>

        <CustomAccordian data={aboutData} open={open} setOpen={setOpen} />
      </Box>
    </Box>
  );
};

const Attendance = () => {
  const [open, setOpen] = useState(null);
  return (
    <Box sx={{ maxWidth: "1400px", margin: "0 auto", py: 20 }}>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "end",
            maxHeight: "420px",
          }}
        >
          <UserIcon />
        </Box>
        <Box sx={{ width: "50%", height: "100%" }}>
          <Box sx={{ display: "flex", mb: 3, justifyContent: "end" }}>
            <Typography sx={{ ...sectionTitle, color: "#CDCDCD" }}>
              EASY
            </Typography>
            <Typography sx={{ ...sectionTitle, color: "var(--light-pr-clr)" }}>
              ATTENDANCE
            </Typography>
          </Box>
          <CustomAccordian
            data={attendanceData}
            open={open}
            setOpen={setOpen}
          />
        </Box>
      </Box>
    </Box>
  );
};

const EasyTrack = () => {
  const [open, setOpen] = useState(null);
  return (
    <Box sx={{ maxWidth: "1400px", margin: "0 auto", py: 12 }}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%", height: "100%" }}>
          <Box sx={{ display: "flex", mb: 3 }}>
            <Typography sx={{ ...sectionTitle, color: "#CDCDCD" }}>
              EASY
            </Typography>
            <Typography sx={{ ...sectionTitle, color: "var(--light-pr-clr)" }}>
              TRACK
            </Typography>
          </Box>
          <CustomAccordian data={easyTrackData} open={open} setOpen={setOpen} />
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            justifyContent: "end",
            maxHeight: "420px",
          }}
        >
          <HomeComputerIcon />
        </Box>
      </Box>
    </Box>
  );
};

const Features = () => {
  const [hover, setHover] = useState(1);

  return (
    <Box sx={{ maxWidth: "1400px", margin: "0 auto", py: 12 }}>
      <Box sx={{ display: "flex", mb: 5, justifyContent: "center" }}>
        <Typography sx={{ ...sectionTitle, color: "var(--light-pr-clr)" }}>
          KEY
        </Typography>
        <Typography sx={{ ...sectionTitle, color: "#CDCDCD" }}>
          FEATURES
        </Typography>
      </Box>

      <Grid container rowSpacing={"50px"} columnSpacing={"20px"} px={10}>
        {features.map((feacher, i) => (
          <Grid key={i} item md={4}>
            <Box
              bgcolor={"red"}
              onMouseEnter={() => setHover(i)}
              sx={{
                bgcolor: hover === i ? "var(--primary-color)" : "",
                ".MuiTypography-root": {
                  color: hover === i ? "white" : "#797777",
                },
                p: 3,
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  bgcolor: "var(--light-pr-clr)",
                  height: "57px",
                  width: "60px",
                  borderRadius: "4px",
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "var(--text-medium)",
                  ...fontBold,
                  my: 2,
                }}
              >
                {feacher?.title}
              </Typography>
              <Typography sx={{ fontSize: "14px" }}>
                {feacher?.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const Pricing = () => {
  const [hover, setHover] = useState(1);

  return (
    <Box sx={{ maxWidth: "1400px", margin: "0 auto", py: 12 }}>
      <Box sx={{ display: "flex", mb: 5 }}>
        <Typography sx={{ ...sectionTitle, color: "var(--light-pr-clr)" }}>
          OUR
        </Typography>
        <Typography sx={{ ...sectionTitle, color: "#CDCDCD" }}>
          PRICING
        </Typography>
      </Box>

      <Grid container alignItems={"center"}>
        {[...new Array(4)].map((_, i, arr) => (
          <Grid key={i} item md={3} bgcolor={"re"}>
            <Box
              onMouseEnter={() => setHover(i)}
              sx={{
                bgcolor: hover === i ? "var(--primary-color)" : "",
                ".MuiTypography-root": {
                  color: hover === i ? "white" : "var(--text-medium)",
                },
                p: 3,
                cursor: "pointer",
                border: "1px solid var(--primary-color)",
                height: hover === i && "400px",
                // transition: "height 1s ease",
              }}
            >
              <Box
                sx={{
                  ...styles.flexCenter,
                  flexDirection: "column",
                  gap: "10px",
                  mb: 1,
                }}
              >
                <WaterPlanIcon
                  fill={hover === i ? "white" : "var(--primary-color)"}
                />
                <Typography sx={{ fontSize: "18px", ...fontBold }}>
                  Regular
                </Typography>
                <Box sx={{ display: "flex", alignItems: "end", gap: "4px" }}>
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    <Typography>৳</Typography>
                    <Typography style={{ fontSize: "48px", lineHeight: "1" }}>
                      5000
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "14px" }}>/month</Typography>
                </Box>
              </Box>

              {[...new Array(4)].map((_, i) => (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    height: "40px",
                  }}
                >
                  <TickIcon style={{ width: "8%" }} />
                  <Typography sx={{ fontSize: "14px", width: "92%" }}>
                    With an appropriate system in place.
                  </Typography>
                </Box>
              ))}

              {hover === i && (
                <Box sx={{ textAlign: "center", mt: 5 }}>
                  <Button sx={primaryButton}>Purchase</Button>
                </Box>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const Footer = () => {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  return (
    <Box sx={{ maxWidth: "1400px", margin: "0 auto" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", py: 8 }}>
        <Box sx={{ width: "40%", ".MuiTypography-root": { color: "white" } }}>
          <LogoIcon fill="#fff" />
          <Typography my={4} sx={{ color: "white" }}>
            With an appropriate system in place, you can optimize your HR and
            payroll procedures, leading to time and cost savings, along with
            decreased errors.With an appropriate system in place, you can
            optimize your HR and payroll procedures, leading to time and cost
            savings, along with decreased errors.
          </Typography>

          <Box
            sx={{
              bgcolor: "var(--dark-pr-clr)",
              p: 3.5,
              borderRadius: "5px",
              height: "130px",
            }}
          >
            <Typography sx={{ ...fontBold, fontSize: "18px" }}>
              Fly Far Tech
            </Typography>
            <Typography>
              78/E 3rd Floor Purana Paltan line, Bijay nagar, Dhaka
            </Typography>
            <Box
              mt={1}
              sx={{ display: "flex", alignItems: "center", gap: 0.8 }}
            >
              <TelephoneIcon />
              <Typography>Helpline</Typography>
              <Typography sx={fontBold}>+880 15185818891</Typography>
            </Box>
            <Box
              mt={3}
              sx={{ ...styles.flexCenter, justifyContent: "space-between" }}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Typography
                  onClick={() => navigate("/termsAndConditions")}
                  sx={{
                    fontSize: "14px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    textUnderlineOffset: "4px",
                  }}
                >
                  Terms And Conditions
                </Typography>
                <Typography
                  onClick={() => navigate("/privacyPolicy")}
                  sx={{
                    fontSize: "14px",
                    textDecoration: "underline",
                    cursor: "pointer",
                    textUnderlineOffset: "4px",
                  }}
                >
                  Privacy Policy
                </Typography>
              </Box>
              <Box sx={{ ...styles.flexCenter, justifyContent: "end", gap: 1 }}>
                <Typography
                  mr={2}
                  style={{ color: "var(--primary-color)", fontSize: "14px" }}
                >
                  Follow us
                </Typography>
                <FbIcon />
                <InstaIcon />
                <LinkedinIcon />
                <TwitterIcon />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "55%" }}>
          <Typography
            sx={{
              fontSize: "30px",
              color: "var(--light-pr-clr)",
              ...fontBold,
              mt: 4,
              mb: 3,
            }}
          >
            Frequently Asked Questions ?
          </Typography>

          <CustomAccordian data={faq} open={open} setOpen={setOpen} />
        </Box>
      </Box>

      <Box
        mt={10}
        py={3}
        sx={{
          borderTop: "1px solid #D9D9D9",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FFTLogo />
        <Typography
          style={{
            color: "white",
            fontSize: "14px",
            width: "40%",
            textAlign: "center",
          }}
        >
          © 2018, All Rights Reserved. Use of the{" "}
          <span style={{ color: "#086922", fontWeight: "bold" }}>
            Fly Far Tech
          </span>{" "}
          websites and our Company acceptance of our Terms and Conditions and
          Privacy Policy. Developed by Fly Far Tech.
        </Typography>
      </Box>
    </Box>
  );
};

const CustomAccordian = ({ data, open, setOpen }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      {data?.map((d, i) => (
        <Box key={i} sx={{ borderRadius: "5px", bgcolor: "#F2F2F2" }}>
          <Box
            onClick={() => setOpen(open === i + 1 ? null : i + 1)}
            sx={{
              ...styles.flexCenter,
              minHeight: "70px",
              px: 2,
              gap: 2,
              height: "100%",
              bgcolor: i + 1 === open ? "var(--dark-pr-clr)" : "#F2F2F2",
              cursor: "pointer",
              borderRadius: i + 1 === open ? "5px 5px 0 0" : "5px",
              transition: "border-radius 0.2s ease 0.2s",
              ":hover": {
                bgcolor: "var(--dark-pr-clr)",
                ".MuiTypography-root": {
                  color: "white",
                },
              },
            }}
          >
            <TickIcon />
            <Typography
              sx={{
                color: i + 1 === open ? "white" : "var(--text-medium)",
                fontSize: "14px",
              }}
            >
              {d?.title}
            </Typography>
          </Box>

          <Collapse in={i + 1 === open} timeout={300}>
            <Box>
              <Typography
                sx={{ color: "var(--text-medium)", fontSize: "14px", p: 2 }}
              >
                {d?.description}
              </Typography>
            </Box>
          </Collapse>
        </Box>
      ))}
    </Box>
  );
};

const aboutData = [
  {
    title: "Real-time Location Tracking",
    description:
      "HRM Pro offers unparalleled accuracy with its real-time location tracking feature. Monitor employee locations as they check in and out, ensuring precise attendance records and timely updates. This feature enhances operational oversight and accountability, making it easy to verify the whereabouts of your team, whether they are on-site or working remotely.",
  },
  {
    title: "Integration with Payroll Systems",
    description:
      "Seamlessly integrate with your existing payroll systems to streamline the payment process. Our app ensures accurate and timely payroll by syncing attendance data directly with your payroll software.",
  },
  {
    title: "Seamless Integration",
    description:
      "HRM Pro seamlessly integrates with your existing systems and tools, simplifying the process of managing attendance and schedules. Our app connects effortlessly with popular HR platforms, scheduling software, and calendar applications, ensuring a smooth workflow and reducing administrative overhead. Experience streamlined operations and enhanced efficiency with our easy-to-integrate solution.",
  },
  {
    title: "Automated Reports",
    description:
      "Generate detailed attendance reports with just a few clicks. Our app automatically compiles data, so you spend less time on paperwork and more time on strategic decisions.",
  },
];

const attendanceData = [
  {
    title: "Secure and Reliable",
    description:
      "Your data is our priority. Our app employs advanced security protocols to safeguard all location and attendance information, ensuring that it is accessible only to authorized users. Enjoy peace of mind with our commitment to data privacy and system reliability, making HRM Pro a dependable choice for your attendance management needs.",
  },
  {
    title:
      "Drowning in HR Details? Let Our App Rescue You and Reclaim Your Focus on What Matters Most!",
    description:
      "Stop letting administrative tasks eat away at your valuable time. With our GPS Tracking Attendance App, you can automate attendance management, enhance accuracy, and shift your focus back to growing your business. Let us handle the details so you can drive innovation and success.",
  },

  {
    title: "Simple Navigation",
    description:
      "Navigate through the app with ease thanks to a clean, organized layout. Key features are accessible through a straightforward menu, ensuring that users can quickly find what they need without unnecessary clicks or confusion.",
  },
  {
    title: "Interactive Dashboard",
    description:
      "The dashboard provides a comprehensive overview of attendance data at a glance. With real-time updates and visual summaries, managers can instantly access important metrics like current employee locations, check-in/out times, and attendance trends.",
  },
  {
    title: "Customizable Views",
    description:
      "Tailor the interface to fit your specific needs. Customize your dashboard to display the most relevant information for your role, whether it’s a daily attendance summary, detailed employee reports, or geofencing alerts.",
  },
];

const easyTrackData = [
  {
    title: "Easy Check-In Check-Out",
    description:
      "Employees can clock in and out with just a tap. The check-in screen is designed to be straightforward, minimizing the steps required to log their time and reducing the chance of errors. GPS functionality activates automatically, so employees don’t need to worry about manually entering their location.",
  },
  {
    title: "Clear Notifications",
    description:
      "Stay informed with easy-to-understand notifications. Whether it’s a reminder for an upcoming shift or an alert about an employee’s location, notifications are clearly displayed and actionable, ensuring you never miss important updates.",
  },
  {
    title: "Interactive Maps",
    description:
      "Visualize employee locations with interactive maps. Zoom in and out, switch between different map views, and get a clear sense of where your team is working. The map is easy to navigate and integrates seamlessly with GPS data for accurate tracking.",
  },
  {
    title: "Mobile-Friendly Design",
    description:
      "Access all features on the go with our mobile-friendly design. The app is optimized for both smartphones and tablets, ensuring that you can manage attendance and track employee locations from anywhere, anytime.",
  },
];

const features = [
  {
    title: "Attendance Management",
    description:
      "Our app simplifies the process of monitoring attendance by providing precise, live updates on who is present and where they are, whether in the office or in the field. By automating check-ins and check-outs, HRM Pro reduces manual effort and ensures accurate records. With an intuitive interface and seamless integration with existing systems, it offers customizable reporting and robust security, making it the perfect solution for efficient, reliable attendance management.",
  },
  {
    title: "Schedule Staff Shifts",
    description:
      "HRM Pro transforms shift management with its intuitive live location tracking and scheduling features. Effortlessly create and assign shifts, monitor employee check-ins and check-outs in real-time, and ensure accurate attendance records. Our app automates time tracking, reducing administrative tasks and ensuring smooth operations, whether managing office hours or remote work.",
  },
  {
    title: "Leave Management",
    description:
      "Employees can easily request time off through the app, and managers can review and approve requests with ease. Our app provides real-time updates on leave statuses and integrates seamlessly with attendance data, ensuring accurate records and smooth coordination for efficient workforce management.",
  },
  {
    title: "Employee Monitoring",
    description:
      "Real-time location tracking allows you to stay informed about employee whereabouts and ensure they are where they need to be. With detailed attendance data and location insights, HRM Pro supports effective management and accountability, both in the office and in the field.",
  },
  {
    title: "Digital Notice Board",
    description:
      "Post important updates, announcements, and information in one central location accessible to all employees. This feature ensures that key messages are communicated clearly and promptly, improving internal communication and ensuring everyone stays up-to-date.",
  },
  {
    title: "Reminders & Notifications",
    description:
      "Receive real-time alerts for check-ins, check-outs, shift changes, and important updates, ensuring that you are always informed about crucial events. Our app’s notifications keep you and your team in sync, enhancing efficiency and responsiveness in your daily operations.",
  },
];

const faq = [
  {
    title: "What does HRM Pro Software do?",
    description: "",
  },
  {
    title: "Is HRM Pro software suitable for small businesses in Bangladesh?",
    description: "",
  },
  {
    title: "Do you have a mobile application for HRM Pro?",
    description: "",
  },
  {
    title: "Do you provide support?",
    description: "",
  },
  {
    title: "How does HRM Pro ensure the security of my employee data?",
    description: "",
  },
  {
    title: "Is HRM Pro software a cloud-based HR software in Bangladesh?",
    description: "",
  },
  {
    title: "Can I see a demo of HRM Pro HR management software?",
    description: "",
  },
];

export default Home;
