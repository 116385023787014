import {
  Box,
  IconButton,
  Link,
  TextField,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  Zoom,
} from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

import moment from "moment";
import React, { useState } from "react";
import SingleAttendenceDetails from "../../OfficeAttendence/SingleAttendenceDetails";
// import SearchIcon from "@mui/icons-material/Search";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const OnTime = ({ data, setSearch, value }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState();

  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data?.slice(indexOfFirstItem, indexOfLastItem);

  // console.log(currentData);

  const handleOpen = (employeId, employeeName) => {
    setEmployeeId(employeId);
    setEmployeeName(employeeName);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // console.log("click");
  };

  const EmployeeCard = ({ item }) => (
    <Box
      sx={{
        mt: 2.5,
        display: "flex",
        // alignItems: "flex-start",
        // justifyContent: "space-between",
        p: 1,
      }}
    >
      <Box sx={{ display: "flex", gap: "5px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--primary-color)",
            height: "40px",
            width: "40px",
            borderRadius: "50%",
            fontSize: "14px",
            color: "white",
          }}
        >
          {
            item?.employeeName
              .split(" ")
              .filter((_, i, arr) => i === 0 || i === arr.length - 1)[0][0]
          }{" "}
          {
            item?.employeeName
              .split(" ")
              .filter((_, i, arr) => i === 0 || i === arr.length - 1)[1][0]
          }
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: 1,
            // background: "blue",
            width: "80%",
          }}
        >
          <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
            {item?.employeeName}
          </Typography>
          <Typography sx={{ fontSize: "11px", color: "var(--primary-color)" }}>
            {item?.designation},{" "}
            <span
              style={{
                color: "var( --text-medium)",
              }}
            >
              {item?.deptName}
            </span>
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
              gap: 1,
              // background: "red"
            }}
          >
            <Typography sx={{ fontSize: "11px" }}>
              Check In - {item?.checkInStatus || "..:.."}
            </Typography>
            <Typography sx={{ fontSize: "11px", ml: 1 }}>
              Check Out - {item?.checkOutStatus || "..:.."}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "11px" }}>
            {/* Break Time - {breakTime} */}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ml: 1 }}>
        {/* <Link sx={{ fontSize: "10px" }}>View</Link> */}
      </Box>
    </Box>
  );

  // console.log(currentData);

  return (
    <Box>
      <input
        type="text"
        placeholder={`Search Employee`}
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          padding: "10px 10px 10px 10px",
          width: "90%",
        }}
        onChange={(e) => setSearch(e.target.value)}
      ></input>

      <Box className="employee-list-container">
        {currentData.length === 0 ? ( // If currentData is empty or being loaded, show the loader
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            {isLoading ? ( // If data is still loading, show CircularProgress
              <CircularProgress />
            ) : (
              // If data is not being loaded and currentData is empty, show "No data found" message
              <Typography variant="body1">No data found</Typography>
            )}
          </Box>
        ) : (
          <Box className="employee-list">
            {currentData.map((item, index) => (
              <Box
                onClick={() => {
                  handleOpen(item?.employeeId, item?.employeeName);
                }}
              >
                <EmployeeCard key={index} item={item} />
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ p: 3, minHeight: "450px" }}>
          <SingleAttendenceDetails
            currentDate={currentDate}
            employeeId={employeeId}
            employeeName={employeeName}
          />
        </Box>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ cursor: "pointer" }}
        >
          <Typography sx={{ fontSize: "12px", color: "#5B9AE4" }}>
            Previous
          </Typography>
        </Button>
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={indexOfLastItem >= data.length}
          sx={{ cursor: "pointer" }}
        >
          <Typography sx={{ fontSize: "12px", color: "#5B9AE4" }}>
            Next
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default OnTime;
